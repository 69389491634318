import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Columns, Column } from "../components/CompoundComponents/Columns"
import { Link } from "gatsby"
import Share from "../components/Share"
import Ymaps from "../components/Ymaps"

const FooterComponent = ({ intl }) => (
  <footer className="footer">
    <div className="container">
      <Columns centered>
        <Column is3>
          <div className="icon-text py-05 mb-1">
            <span className="icon">
              <FontAwesomeIcon
                icon="map-marker-alt"
                size="sm"
                color="#61a118"
              />
            </span>
            <span className="has-text-weight-bold">
              {intl.formatMessage({
                id: `footer.col_1.title`,
              })}
            </span>
            <p className="block">
              {intl.formatMessage({
                id: `footer.col_1.location`,
              })}
            </p>
          </div>
          <div className="icon-text py-05 mb-1">
            <span className="icon">
              <FontAwesomeIcon icon="phone" size="sm" color="#61a118" />
            </span>
            <a href="tel:+998953008885" className="has-text-primary mr-05">
              <span>
                {intl.formatMessage({
                  id: `footer.col_1.phone`,
                })}
              </span>
            </a>
            <a href="tel:+998945001096" className="has-text-primary">
              <span>
                {intl.formatMessage({
                  id: `footer.col_1.phone2`,
                })}
              </span>
            </a>
          </div>
        </Column>
        <Column is4>
          <div className="is-shadow">
            <Ymaps height="200px" />
          </div>
        </Column>
        <Column is2>
          <Link
            to="/rooms"
            className="navbar-item is-size-7 is-uppercase has-text-weight-bold"
            activeClassName="is-active"
          >
            {intl.formatMessage({ id: "header.nav.item-2" })}
          </Link>
          <Link
            to="/contact"
            className="navbar-item is-size-7 is-uppercase has-text-weight-bold"
            activeClassName="is-active"
          >
            {intl.formatMessage({
              id: `header.nav.item-4`,
            })}
          </Link>
        </Column>

        <Column is3 centered tmcentered>
          <Share />
        </Column>
        <Column is8 centered>
          <p className="is-size-7 mb-1">
            © {new Date().getFullYear()} Ниҳол сиҳатгоҳи.{" "}
            {intl.formatMessage({ id: "footer.copyright" })}
            <br />
            Сайт разработан{" "}
            <a
              href="https://www.rahimovteam.com/ru/"
              target="_blank"
              className="has-text-link"
            >
              www.rahimovteam.com
            </a>
          </p>
          <p className="is-size-7 mb-1">
            {intl.formatMessage({ id: "footer.privacy.desc" })}{" "}
            <Link
              to="/privacy"
              className="is-size-7 has-text-primary"
              activeClassName="is-active"
            >
              {intl.formatMessage({ id: "footer.privacy.title" })}
            </Link>
          </p>
        </Column>
      </Columns>
    </div>
  </footer>
)

export default injectIntl(FooterComponent)

import React, { Component, createContext } from "react"
import { navigate } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import {
  faWifi,
  faSwimmingPool,
  faPhone,
  faMapMarkerAlt,
  faTv,
  faHandHoldingWater,
  faUserNurse,
  faTree,
  faHeadset,
} from "@fortawesome/free-solid-svg-icons"

import FooterComponent from "../components/Footer"

import "../pages/mystyles.scss"

const ShoppingCart = createContext()

export const ShoppingConsumer = ShoppingCart.Consumer

library.add(
  fab,
  faWifi,
  faTv,
  faSwimmingPool,
  faPhone,
  faMapMarkerAlt,
  faHandHoldingWater,
  faUserNurse,
  faTree,
  faHeadset
)

class Layout extends Component {
  constructor() {
    super()

    this.handleAddToCart = this.handleAddToCart.bind(this)
    this.handleRemoveCart = this.handleRemoveCart.bind(this)
    this.handleButton = this.handleButton.bind(this)
    this.handleSuccess = this.handleSuccess.bind(this)
    this.handleService = this.handleService.bind(this)
    this.handleStartDate = this.handleStartDate.bind(this)
    this.handleEndDate = this.handleEndDate.bind(this)
    this.handleQuantity = this.handleQuantity.bind(this)
    this.handleName = this.handleName.bind(this)
    this.handlePhone = this.handlePhone.bind(this)

    this.state = {
      shoppingStatus: false,
      handleAdd: this.handleAddToCart,
      handleRemove: this.handleRemoveCart,
      handleButton: this.handleButton,
      handleSuccess: this.handleSuccess,
      handleService: this.handleService,
      handleStartDate: this.handleStartDate,
      handleEndDate: this.handleEndDate,
      handleQuantity: this.handleQuantity,
      handleName: this.handleName,
      handlePhone: this.handlePhone,
      cart: [],
      service: [],
      startDate: "",
      endDate: "",
      quantity: "",
      name: "",
      phone: "",
    }
  }

  handleAddToCart(data) {
    const val = this.props.intl.formatMessage({
      id: `products.${data}.title`,
    })
    console.log(val)
    this.setState(prevState => ({
      cart: [...prevState.cart, val],
    }))
  }

  handleService(data) {
    const val = this.props.intl.formatMessage({
      id: `header.dropdown_item_4.${data}`,
    })
    console.log(val)
    console.log(data)
    this.setState(prevState => ({
      service: [...prevState.service, { name: val, id: data }],
    }))
  }

  handleQuantity(data) {
    console.log(data)
    this.setState({
      quantity: data,
    })
  }

  handleName(data) {
    console.log(data)
    this.setState({
      name: data,
    })
  }

  handlePhone(data) {
    console.log(data)
    this.setState({
      phone: data,
    })
  }

  handleStartDate(data) {
    console.log(data)
    this.setState({
      startDate: data,
    })
  }

  handleEndDate(data) {
    console.log(data)
    this.setState({
      endDate: data,
    })
  }

  handleRemoveCart(data) {
    console.log(data)
    this.setState({
      service: this.state.service.filter(function(item) {
        return item.id !== data
      }),
    })
  }

  handleButton() {
    this.setState({
      service: [],
      startDate: "",
      endDate: "",
      quantity: "",
      name: "",
      phone: "",
    })
  }

  handleSuccess() {
    navigate("/")
  }

  render() {
    return (
      <ShoppingCart.Provider value={this.state}>
        <main>{this.props.children}</main>
      </ShoppingCart.Provider>
    )
  }
}

export default injectIntl(Layout)

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-beauty-js": () => import("./../../../src/pages/beauty.js" /* webpackChunkName: "component---src-pages-beauty-js" */),
  "component---src-pages-booking-js": () => import("./../../../src/pages/booking.js" /* webpackChunkName: "component---src-pages-booking-js" */),
  "component---src-pages-cafe-js": () => import("./../../../src/pages/cafe.js" /* webpackChunkName: "component---src-pages-cafe-js" */),
  "component---src-pages-chayhona-js": () => import("./../../../src/pages/chayhona.js" /* webpackChunkName: "component---src-pages-chayhona-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cottage-js": () => import("./../../../src/pages/cottage.js" /* webpackChunkName: "component---src-pages-cottage-js" */),
  "component---src-pages-diagnostics-js": () => import("./../../../src/pages/diagnostics.js" /* webpackChunkName: "component---src-pages-diagnostics-js" */),
  "component---src-pages-finnish-sauna-js": () => import("./../../../src/pages/finnish-sauna.js" /* webpackChunkName: "component---src-pages-finnish-sauna-js" */),
  "component---src-pages-fitness-js": () => import("./../../../src/pages/fitness.js" /* webpackChunkName: "component---src-pages-fitness-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jakuzzi-js": () => import("./../../../src/pages/jakuzzi.js" /* webpackChunkName: "component---src-pages-jakuzzi-js" */),
  "component---src-pages-junior-suite-js": () => import("./../../../src/pages/junior-suite.js" /* webpackChunkName: "component---src-pages-junior-suite-js" */),
  "component---src-pages-luxury-js": () => import("./../../../src/pages/luxury.js" /* webpackChunkName: "component---src-pages-luxury-js" */),
  "component---src-pages-luxury-vip-js": () => import("./../../../src/pages/luxury-vip.js" /* webpackChunkName: "component---src-pages-luxury-vip-js" */),
  "component---src-pages-massage-js": () => import("./../../../src/pages/massage.js" /* webpackChunkName: "component---src-pages-massage-js" */),
  "component---src-pages-mineral-js": () => import("./../../../src/pages/mineral.js" /* webpackChunkName: "component---src-pages-mineral-js" */),
  "component---src-pages-nuga-best-js": () => import("./../../../src/pages/nuga-best.js" /* webpackChunkName: "component---src-pages-nuga-best-js" */),
  "component---src-pages-physiotherapy-js": () => import("./../../../src/pages/physiotherapy.js" /* webpackChunkName: "component---src-pages-physiotherapy-js" */),
  "component---src-pages-phytosauna-js": () => import("./../../../src/pages/phytosauna.js" /* webpackChunkName: "component---src-pages-phytosauna-js" */),
  "component---src-pages-pool-js": () => import("./../../../src/pages/pool.js" /* webpackChunkName: "component---src-pages-pool-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-rooms-js": () => import("./../../../src/pages/rooms.js" /* webpackChunkName: "component---src-pages-rooms-js" */),
  "component---src-pages-treatment-js": () => import("./../../../src/pages/treatment.js" /* webpackChunkName: "component---src-pages-treatment-js" */),
  "component---src-pages-turkish-sauna-js": () => import("./../../../src/pages/turkish-sauna.js" /* webpackChunkName: "component---src-pages-turkish-sauna-js" */),
  "component---src-pages-vegetables-js": () => import("./../../../src/pages/vegetables.js" /* webpackChunkName: "component---src-pages-vegetables-js" */)
}


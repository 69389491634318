import React from "react"
import { YMaps, Map, Placemark } from "react-yandex-maps"

const Ymaps = ({ height }) => (
  <YMaps>
    <div className="mb-1">
      <Map
        width="100%"
        height={height}
        defaultState={{
          center: [41.144606, 72.113044],
          zoom: 10,
        }}
      >
        <Placemark geometry={[41.144606, 72.113044]} />
      </Map>
    </div>
  </YMaps>
)

export default Ymaps
